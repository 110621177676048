import React, { useState } from 'react';
import './FaqBanner.css'; // Certifique-se de substituir pelo caminho real do seu arquivo CSS

const abrirLink = () => {
  window.open('https://pay.hotmart.com/D90547365P', '_blank');
};
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: 'O que é a Escola dos Cards: Importação TCG Profissional?',
      answer: 'É um curso que ensina como importar cartas de TCG, produtos selados e acessórios dos Estados Unidos e Europa para o Brasil, visando lucros de até 500% para gerar uma renda extra com seu hobby.',
    },
    {
      question: 'Quais são os métodos de importação ensinados no curso?',
      answer: 'O curso ensina quatro métodos diferentes de importação, incluindo importação direta de outros jogadores, dos Estados Unidos e Europa, importação sem taxa alfandegária, e de produtos selados e acessórios.',
    },
    {
      question: 'Quais são os bônus oferecidos no curso?',
      answer: 'Os bônus incluem um guia para montar um estoque inteligente, um método de precificação profissional e estratégias para fidelizar clientes e garantir recorrência nas vendas.',
    },
    {
      question: 'O curso é apenas para yugioh?',
      answer: 'Não, o curso serve para todos os card games! Meu foco é no yugioh por entender mais do jogo. Porém já importei Digimon,Pokemon e One Piece. E qualquer outro é possível, mas claro, uns dão mais lucros que outros.',
    },
    {
      question: 'Quais são as opções de pagamento para o curso?',
      answer: 'O curso pode ser adquirido por 12 parcelas de R$39,62 ou um pagamento único de R$397 à vista.',
    },
    {
      question: 'O curso é adequado para quem não entende nada de importação?',
      answer: 'Sim, a Escola dos Cards é ideal tanto para iniciantes quanto para aqueles que já têm experiência no mundo do TCG, pois ensina do absoluto zero de maneira direta e descomplicada.',
    },
    {
      question: 'Já faço minhas importações, vale a pena pra mim o curso?',
      answer: 'Sim, a Escola dos Cards é ideal tanto para iniciantes quanto para aqueles que já têm experiência no mundo das importaçõesm, pois ensina várias formas e dicas em cada uma delas para aumentar seu lucro, além dos bonus sobre vendas',
    },
    // Adicione mais perguntas conforme necessário
  ];
  

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="title">Perguntas Frequentes</h2>
      {faqData.map((faq, index) => (
        <div key={index}>
          <div
            className={`question ${activeIndex === index ? 'active-question' : ''}`}
            onClick={() => toggleAnswer(index)}
            tabIndex={0}
            role="button"
          >
            <span>{faq.question}</span>
            <span>{activeIndex === index ? '-' : '+'}</span>
          </div>
          <div className={`answer ${activeIndex === index ? 'active' : ''}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
      <button class="buy-Button-about" onClick={abrirLink}>Lucrar com seu Hobby</button>
    </div>
  );
};

export default FAQ;
