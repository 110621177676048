import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'; // Caminho para o arquivo de estilos CSS
import carousel1 from '../assets/carousel1.png'
import carousel2 from '../assets/carousel2.png'
import carousel3 from '../assets/carousel3.png'
import carousel4 from '../assets/carousel4.jpeg'
import carousel5 from '../assets/carousel5.jpeg'
import carousel6 from '../assets/carousel6.jpeg'

const abrirLink = () => {
    window.open('https://pay.hotmart.com/D90547365P', '_blank');
  };
const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, // Ativar o autoplay
        autoplaySpeed: 3000, // Intervalo de 3 segundos
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    

    const slidesData = [
        // Adicione aqui os dados das imagens e textos
        {id: 1, image: carousel1, text: ''},
        {id: 2, image: carousel2, text: ''},
        {id: 3, image: carousel3, text: ''},
        {id: 4, image: carousel4, text: ''},
        {id: 5, image: carousel5, text: ''},
        {id: 6, image: carousel6, text: ''},
        // Repita para mais slides
    ];

    return (
        <div className="carousel-wrapper">
        <div className="carousel-container">
            <p className="carousel-title">Não perca tempo! Transforme já seu hobby em uma renda extra!</p>
            <Slider {...settings}>
                {slidesData.map(slide => (
                    <div key={slide.id} className="carousel-slide">
                        <img src={slide.image} alt="slide" className="carousel-image"/>
                        <p>{slide.text}</p>
                    </div>
                ))}
            </Slider>
            
        </div>
        <button class="buy-Button-about-carousel" onClick={abrirLink}>Quero renda extra</button>
        </div>
        
    );
};

export default Carousel;
