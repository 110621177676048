import React from 'react';
import ReactPlayer from 'react-player';
import './MainBanner.css';


const abrirLink = () => {
  window.open('https://pay.hotmart.com/D90547365P', '_blank');
};

const MainBanner = () => {
  return (
    <div className="banner-container">
      <div className="content-wrapper">
      <h1 className="titulo1">
      A Escola dos Cards: Importação TCG Profissional
      </h1>
      <h2 className="titulo2">
        Já pensou em lucrar de <span className='destaque1'>R$1000 a R$5000/Mês</span> com seu hobby tcg? Com a escola dos cards isso é possivel! Aprenda de uma vez por todas como importar cartas dos Estados Unidos e Europa e ter <span className='destaque1'>lucros de até 500%</span>
      </h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        
<div className="video-container">
  <iframe 
    className="responsive-iframe"
    src="https://www.youtube.com/embed/mjEHyHoNs9U?autoplay=1"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen>
  </iframe>
</div>

      </div>
      </div>
      <button class="buy-Button" onClick={abrirLink}>Começar Importar</button>
    </div>
  );
};

export default MainBanner;
