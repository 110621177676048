import React from 'react';
import AboutBgImg from "../assets/cartas.png"
import './AboutBanner.css';
const abrirLink = () => {
    window.open('https://pay.hotmart.com/D90547365P', '_blank');
  };
const AboutBanner = () => {
    return (
        <div className="about-section-container">
            <div className="about-image-container">
            <img src={AboutBgImg} className="levitate-image"></img>
            </div>
            <div className="about-text-container">
                <h1 className="title-text-container"> Seja um verdadeiro mestre das cartas!</h1>
                <p className='primary-text'>Aprenda do absoluto zero de maneira direta e descomplicada o passo a passo para importar cartas avulsas, produtos selados e acessórios dos Estados Unidos e Europa para o Brasil, atendendo a todos os gostos dos seus clientes. Atraves dos 4 Métodos diferentes que serão ensinados no curso você terá um leque de possibilidades para gerar uma renda extra todos os meses com a importação e ajudando a você não só diminuir os gastos com seu hobby, mas lucrar com ele!
                </p>
            <button class="buy-Button-about" onClick={abrirLink}>Lucrar agora</button>
            </div>
            
        </div>
    ) }

export default AboutBanner