import React from 'react';
import './WhatsappBtn.css'; // Arquivo de estilos para posicionar o botão
import zap from '../assets/whats.png'
const WhatsappBtn = () => {
  return (
    <div className="whatsapp-btn">
      <a href="https://api.whatsapp.com/send?phone=5514991414514" target="_blank" rel="noopener noreferrer">
        <img src={zap} alt="WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsappBtn;