import './App.css';
import MainBanner from './components/MainBanner';
import AboutBanner from './components/AboutBanner';
import CardBanner from './components/cardBanner';
import FaqBanner from './components/FaqBanner'; 
import WhatsappBtn from './components/WhatsappBtn';
import Carousel from './components/Carousel';
import AboutMe from './components/AboutMe';

function App() {
  return (
    <div className="App">
      <MainBanner/>
      <AboutBanner/>
      <AboutMe/>
      <CardBanner/>
     
      <Carousel/>
       <FaqBanner/> 
      <WhatsappBtn/>
    </div>
  );
}

export default App;