import React from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import 'animate.css';
import './cardBanner.css';
const abrirLink = () => {
  window.open('https://pay.hotmart.com/D90547365P', '_blank');
};
const cardBanner = () => {
  return (
    <div className='card-container animate__animated animate__fadeIn'>
    <div className='card-container'>
        <div className="card">
        <h2>Escola dos Cards: Importação TCG Profissional</h2>
        
        <div className="pricing">
            <div className="highlight-price">
            <p>12x DE:</p>
            <p className="big-highlight">R$39,62</p>
            </div>
            <p className="subtle-text">ou R$ 397 à vista</p>
        </div>
        <ul className="features">
            <li><IoIosCheckmarkCircle className="check-mark" />Acesso à Escola dos Cards</li>
             <li><IoIosCheckmarkCircle className="check-mark" />Importe de até 4 Formas Diferentes</li>
             <li><IoIosCheckmarkCircle className="check-mark" />Importação direto de outros jogadores(Pessoa Fisica)</li>
             <li><IoIosCheckmarkCircle className="check-mark" />Importação dos Estados Unidos e Europa</li>
             <li><IoIosCheckmarkCircle className="check-mark" />Importação sem taxa alfandegária</li>
             <li><IoIosCheckmarkCircle className="check-mark" />Importação de produtos selados e acessórios</li>
             <li><IoIosCheckmarkCircle className="check-mark" /><span className='bonus'>Bônus 1 </span>   Como montar um estoque inteligente</li>
             <li><IoIosCheckmarkCircle className="check-mark" /><span className='bonus'>Bônus 2 </span>   Método de precificação profissional</li>
             <li><IoIosCheckmarkCircle className="check-mark" /><span className='bonus'>Bônus 3 </span>   Fidelizando clientes: garantindo recorrencia</li>
        </ul>
        <button className="cta-button" onClick={abrirLink}>EU QUERO</button>
        </div>
    </div>
    </div>
  );
};

export default cardBanner;