import React from 'react';
import './AboutMe.css'; // Importe o arquivo CSS aqui
import Foto from '../assets/foto.png'
import { IoLogoYoutube } from 'react-icons/io'; // Importação do ícone
const AboutMe = () => {
    return (
        <div className="about-me-container">
            <div className="about-me-content">
                <div className="about-me-text">
                    <p>Olá, sou Matheus, criador do canal MATHEUS-TCG. Há aproximadamente um ano, pouco depois de iniciar minha jornada em Yugioh, encarei um desafio que transformou minha paixão em uma oportunidade única. Incomodado com os altos preços dos cards no Brasil, mergulhei no universo da importação. Meu objetivo inicial era simples: montar meus próprios decks sem afetar meu orçamento. Porém, ao perceber a grande diferença de preços entre o mercado nacional e internacional, uma faísca de empreendedorismo foi acesa. Rapidamente, vi que não só poderia realizar meu sonho de ter decks de qualidade, mas também gerar uma renda extra. A cada passo nessa jornada, meu estoque e lucros cresceram, mostrando o potencial ilimitado desse mercado. Hoje, estou aqui para compartilhar essa experiência e mostrar como você também pode transformar sua frustração em uma história de sucesso financeiro.</p>
                    <a href="https://www.youtube.com/@m-tcg/videos" target="_blank" rel="noopener noreferrer" className="youtube-button">
                        <IoLogoYoutube size={30} /> Conheça meu trabalho
                    </a>
                </div>
                <div className="about-me-image">
                    <img src={Foto} alt="Splash" className="splash-image" />
                    
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
